export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as any) === 'staging';
export const REACT_APP_API_BASE_URL = IS_DEV
  ? `http://localhost:${process.env.REACT_APP_SERVER_APP_PORT}`
  : process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const REACT_APP_ONLINE_LESSON_LEARNER_URL = IS_DEV
  ? `http://localhost:5000`
  : process.env.REACT_APP_ONLINE_LESSON_LEARNER_URL ?? '';
// export const REACT_APP_COGNITO_USER_POOL_ID =
//   process.env.REACT_APP_COGNITO_USER_POOL_ID_FOR_TEACHER;
// export const REACT_APP_COGNITO_USER_POOL_CLIENT_ID =
//   process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_TEACHER;
export const REACT_APP_COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID_FOR_LEARNER;
export const REACT_APP_COGNITO_USER_POOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_LEARNER;
export const REACT_APP_SERVER_GMO_SHOP_ID = process.env.REACT_APP_SERVER_GMO_SHOP_ID;
export const REACT_APP_SERVER_GMO_TOKEN_URL = process.env.REACT_APP_SERVER_GMO_TOKEN_URL;
export const REACT_APP_TERMS_OF_SERVICE_URL = process.env.REACT_APP_TERMS_OF_SERVICE_URL;
export const REACT_APP_TERMS_OF_SERVICE_URL_EN = process.env.REACT_APP_TERMS_OF_SERVICE_URL_EN;
export const REACT_APP_TERMS_OF_SERVICE_URL_CN = process.env.REACT_APP_TERMS_OF_SERVICE_URL_CN;
export const REACT_APP_PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const REACT_APP_PRIVACY_POLICY_JAPAN_URL = process.env.REACT_APP_PRIVACY_POLICY_JAPAN_URL;
export const REACT_APP_PRIVACY_POLICY_ENGLISH_URL = process.env.REACT_APP_PRIVACY_POLICY_ENGLISH_URL;
export const REACT_APP_PRIVACY_POLICY_CHINESE_URL = process.env.REACT_APP_PRIVACY_POLICY_CHINESE_URL;
export const REACT_APP_SPECIFIED_COMMERCIAL_URL = process.env.REACT_APP_SPECIFIED_COMMERCIAL_URL;
export const REACT_APP_CHAT_WEBSOCKET_URL = process.env.REACT_APP_CHAT_WEBSOCKET_URL ?? '';

export const COOKIE_DOMAIN = IS_DEV ? 'localhost' : process.env.REACT_APP_LEARNER_URL!.match(/(?<=https:\/\/).*/)![0];

export const DELETE_FLG = {
  // 削除
  DELETED: '1',
  // 未削除
  AVAILABLE: '0',
} as const;
export type DELETE_FLG_TYPE = typeof DELETE_FLG[keyof typeof DELETE_FLG];
export const STOP_FLG = {
  // 停止
  STOPPED: '1',
  // 未停止
  AVAILABLE: '0',
} as const;
export type STOP_FLG_TYPE = typeof STOP_FLG[keyof typeof STOP_FLG];
export type QUERY_RESULT = number & { QUERY_RESULT: never };
export const ITEM_TYPE = {
  // サブスクリプション購入
  SUBSCRIPTION: 0,
  // ポイント購入
  POINT: 1,
} as const;
export type ITEM_TYPE = typeof ITEM_TYPE[keyof typeof ITEM_TYPE];
export const PAYMENT_TYPE = {
  // credit card
  CREDIT_CARD: 0,
  // PAyPay
  PAYPAY: 1,
} as const;
export type PAYMENT_TYPE = typeof PAYMENT_TYPE[keyof typeof PAYMENT_TYPE];
export const MESSAGE = {
  NO_ERROR: 'NO_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_AUTHORIZED_USER_ACCESS: 'NOT_AUTHORIZED_USER_ACCESS',
} as const;
export type MESSAGE_TYPE = typeof MESSAGE[keyof typeof MESSAGE] | QUERY_RESULT;

export const SERVER_WEBPUSH_PUBLIC_KEY = process.env.SERVER_WEBPUSH_PUBLIC_KEY;
export const HIDDEN_PAYPAY = false;
//Push通知が活用化
export const ACTIVE_FLG = {
  // 活用
  ACTIVE: '1',
  // 未活用
  INACTIVE: '0',
} as const;

export const LESSON_ENTRY_LOG_TYPE = {
  TEACHER_JOINED_LESSON: '1',
  LEARNER_JOINED_LESSON: '2',
} as const;
export const POINT_PURCHASE_LIST: readonly number[] = [100, 200, 300, 400, 500] as const;

export const PATH_NAME = {
  SELF_STUDY: '/self_study',
  HIRAGANA_A_N: '/hiragana_a-n',
  HIRAGANA_GA_PO: '/hiragana_ga-po',
  HIRAGANA_KYA_PYO: '/hiragana_kya-pyo',
  KATAKANA_A_N: '/katakana_a-n',
  KATAKANA_GA_PO: '/katakana_ga-po',
  KATAKANA_KYA_PYO: '/katakana_kya-pyo'
} 

// ====================
// AWS
// ====================
// TODO S3は無印の物をそのまま使うため、名称変更などは勝手にできない
export const S3_BUCKET_URL = 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/';
export const BASE_URL = 'https://sun-resource-tokyo.s3.ap-northeast-1.amazonaws.com/self_study/kana/audio/';
export const S3_FILE = {
  JSON: {
    LIST_SEASON_CHAPTER_SECTION: 'list_season_chapter_section_staging.json',
  },
};

// ====================
// Google
// ====================
/** タグマネージャーID */
export const GTM_ID=process.env.REACT_APP_GTM_ID ?? ""
export const TAG_MANAGET_ARGS = {
  gtmId: GTM_ID
} as const
