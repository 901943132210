import { I18n } from '@aws-amplify/core';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Container } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { Auth } from 'aws-amplify';
import { routerActions } from 'connected-react-router';
import React, { lazy, useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import './App.scss';
import { useAppDispatch, useAppSelector } from './hooks';
import { COGNITO_TRANSLATIONS } from './i18';
import { Shell } from './pages/shell';
import { isLoading, stopLoading } from './slice/loadingSlice';
import { CognitoPayload, signIn, signOutAsync } from './slice/userSlice';
import { format } from 'date-fns';
import { AxiosResponse } from 'axios';
import Agree from './pages/start/agree';
import Start from './pages/start/start';
import SelectLang from './pages/start/select_lang';
import PWAInstall from './pages/pwa/pwa-kokoka';
import TagManager from 'react-gtm-module';
import { IS_STG, IS_PRD, TAG_MANAGET_ARGS } from './configs';
import useEventLogger from './hooks/useEventLogger/useEventLogger';
import { LOGGER_MESSAGES } from './constants/message';

// const Notice = lazy(() => import('./pages/notice/index'));
const Stories = lazy(() => import('./pages/stories/index'));
const Grammar = lazy(() => import('./pages/grammar/index'));
const Story = lazy(() => import('./pages/story/index'));
const Expression = lazy(() => import('./pages/expression/index'));
const Exercises = lazy(() => import('./pages/exercises/index'));
const Question = lazy(() => import('./pages/question/index'));
const Answer = lazy(() => import('./pages/answer/index'));
const Goal = lazy(() => import('./pages/goal/index'));
const OnlineLesson = lazy(() => import('./pages/online_lesson/index'));
const SelfStudy = lazy(() => import('./pages/self_study/index'));
const WordbankNoun = lazy(() => import('./pages/wordbank_noun/index'));
const WordbankVerb = lazy(() => import('./pages/wordbank_verb/index'));
const WordbankAdjective = lazy(() => import('./pages/wordbank_adjective/index'));
const GrammarNotes = lazy(() => import('./pages/grammar_notes/index'));
const GrammarList = lazy(() => import('./pages/grammar_list/index'));
const Times = lazy(() => import('./pages/times/index'));
const Dates = lazy(() => import('./pages/dates/index'));
const DrillsList = lazy(() => import('./pages/drills_list/index'));
const DrillsTop = lazy(() => import('./pages/drills_top/index'));
const DrillsQuestion = lazy(() => import('./pages/drills_question/index'));
const DrillsAnswer = lazy(() => import('./pages/drills_answer/index'));
const DrillsGoal = lazy(() => import('./pages/drills_goal/index'));
const Notices = lazy(() => import('./pages/notices/index'));
const Settings = lazy(() => import('./pages/settings/index'));
const UserInfo = lazy(() => import('./pages/user_info/index'));
const Contact = lazy(() => import('./pages/contact/index'));
const Signin = lazy(() => import('./pages/signin/index'));
const Signup = lazy(() => import('./pages/start/signup/signup'));
const SignupGrp = lazy(() => import('./pages/start/signup/signup_grp'));
const PushConfirm = lazy(() => import('./pages/start/signup/push_confirm'));
const WordCards = lazy(() => import('./pages/word_cards/index'));
const Lessons = lazy(() => import('./pages/lessons/index'));
const Lesson = lazy(() => import('./pages/lesson/index'));
const ReserveDates = lazy(() => import('./pages/reserve_dates/index'));
const ReserveTimes = lazy(() => import('./pages/reserve_times/index'));
const ReserveContents = lazy(() => import('./pages/reserve_contents/index'));
const QAndA = lazy(() => import('./pages/q_and_a/index'));
const Point = lazy(() => import('./pages/point/index'));
const PointHistory = lazy(() => import('./pages/point_history/index'));
// const Start = lazy(() => import('./pages/start/start2'));
const StudyRecords = lazy(() => import('./pages/study_records/index'));
const Subscription = lazy(() => import('./pages/subscription/index'));
const Questionnaire = lazy(() => import('./pages/questionnaire/index'));
const Top = lazy(() => import('./pages/top/top'));
const HiraganaAn = lazy(() => import('./pages/hiragana_a-n/index'));
const HiraganaGaPo = lazy(() => import('./pages/hiragana_ga-po/index'));
const HiraganaKyaPyo = lazy(() => import('./pages/hiragana_kya-pyo/index'));
const HiraganaAnContents = lazy(() => import('./pages/hiragana_a-n_contents/index'));
const HiraganaGaPoContents = lazy(() => import('./pages/hiragana_ga-po_contents/index'));
const HiraganaKyaRyoContents = lazy(() => import('./pages/hiragana_kya-ryo_contents/index'));
const HiraganaGyaPyoContents = lazy(() => import('./pages/hiragana_gya-pyo_contents/index'));
const HiraganaExercises = lazy(() => import('./pages/hiragana_exercises/index'));
const HiraganaQuestion = lazy(() => import('./pages/hiragana_question/index'));
const HiraganaAnswer = lazy(() => import('./pages/hiragana_answer/index'));
const HiraganaGoal = lazy(() => import('./pages/hiragana_goal/index'));
const KatakanaAn = lazy(() => import('./pages/katakana_a-n/index'));
const KatakanaGaPo = lazy(() => import('./pages/katakana_ga-po/index'));
const KatakanaKyaPyo = lazy(() => import('./pages/katakana_kya-pyo/index'));
const KatakanaAnContents = lazy(() => import('./pages/katakana_a-n_contents/index'));
const KatakanaGaPoContents = lazy(() => import('./pages/katakana_ga-po_contents/index'));
const KatakanaKyaRyoContents = lazy(() => import('./pages/katakana_kya-ryo_contents/index'));
const KatakanaGyaPyoContents = lazy(() => import('./pages/katakana_gya-pyo_contents/index'));
const KatakanaIeDouContents = lazy(() => import('./pages/katakana_ie-dou_contents/index'));
const KatakanaFuyuDeyuContents = lazy(() => import('./pages/katakana_fuyu-deyu_contents/index'));
const KatakanaExercises = lazy(() => import('./pages/katakana_exercises/index'));
const KatakanaQuestion = lazy(() => import('./pages/katakana_question/index'));
const KatakanaAnswer = lazy(() => import('./pages/katakana_answer/index'));
const KatakanaGoal = lazy(() => import('./pages/katakana_goal/index'));

I18n.putVocabulariesForLanguage('ja', COGNITO_TRANSLATIONS);
const PrivateRoute = ({ render, userState, ...rest }: any) => {
  // FIXME 暫定対応 useHistory.listenが使えないためここに置くが何度も実行されるため対策必要
  const { sendInfoLog } = useEventLogger();
  sendInfoLog({
    message: LOGGER_MESSAGES.PV,
    payload: {
    },
  });
  return (
    <Route
      {...rest}
      render={userState.authState === AuthState.SignedIn && userState.user ? render : () => <Redirect to="/signin" />}
    />
  );
};
export const Loading = () => (
  <Container className="App-Loading">
    <Spinner />
  </Container>
);

const extractCognitoPayload = (authenticatedUser: any): CognitoPayload => {
  try {
    return {
      'cognito:username': authenticatedUser.signInUserSession.idToken.payload['cognito:username'],
      'custom:organizationKey': authenticatedUser.attributes['custom:organizationKey'],
      email: authenticatedUser.signInUserSession.idToken.payload.email,
      exp: authenticatedUser.signInUserSession.idToken.payload.exp,
    };
  } catch (error) {
    throw error;
  }
};

const App = () => {
  const userState = useAppSelector((state) => state.user);
  const isloading = useAppSelector(isLoading);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { sendInfoLog } = useEventLogger();
  const { listen } = useHistory();

  useLayoutEffect(() => {
    // FIXME 他のclientでは発火するのにlearnerでは発火しない？
    listen((event) => {
      console.log('event', event);
      sendInfoLog({
        message: LOGGER_MESSAGES.PV,
        payload: {
          event,
        },
      });
    });
    return () => {
      listen(() => {});
    };
  }, []);

  useEffect(() => {
    // // ブラウザバックを禁止する
    // // window.history.pushState(null, "null", null);
    // window.addEventListener("popstate", function() {
    //   alert('ブラウザバックを使わないでください。');
    //   window.history.go(1);
    // });
    let authenticatedUser = {};
    const init = async () => {
      try {
        authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
      } catch (e) {}
      // console.log(authenticatedUser)
      if (authenticatedUser) {
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: extractCognitoPayload(authenticatedUser),
          }),
        );
        dispatch(stopLoading());
      } else {
        await dispatch(signOutAsync());
        dispatch(stopLoading());
        // cognito認証前のページ以外はサインインページへ遷移
        if (
          location.pathname !== '/' &&
          location.pathname !== '/agree' &&
          location.pathname !== '/start' &&
          location.pathname !== '/select_lang' &&
          location.pathname !== '/pwa' &&
          location.pathname !== '/signup' &&
          location.pathname !== '/signup_grp'
        ) {
          dispatch(routerActions.push('/signin'));
        }
      }
    };
    init();
    return onAuthUIStateChange((nextAuthState, authenticatedUser) => {
      if (nextAuthState === AuthState.SignedIn && authenticatedUser) {
        localStorage.setItem('mode', '');
        dispatch(
          signIn({
            authState: AuthState.SignedIn,
            user: extractCognitoPayload(authenticatedUser),
          }),
        );
      }
    });
  }, [dispatch]);
  useEffect(() => {
    if (IS_STG || IS_PRD) {
      TagManager.initialize(TAG_MANAGET_ARGS);
    }
  }, []);
  return isloading ? (
    <Loading />
  ) : (
    <Switch>
      <Route
        exact
        path="/pwa"
        render={() => {
          return window.matchMedia('(display-mode: standalone)').matches ? (
            <Redirect to="/select_lang" />
          ) : (
            <PWAInstall />
          );
        }}
      />
      <Route exact path="/select_lang">
        <SelectLang />
      </Route>
      <Route exact path="/agree">
        <Agree />
      </Route>
      <Route
        exact
        path="/start"
        render={() => {
          // Routing判定でstartページへ行ける？
          return localStorage.getItem('agreed') === 'true' ? <Start /> : <Redirect to="/pwa" />;
        }}
      />
      <React.Suspense fallback={<Loading />}>
        {location.pathname === '/top' ? (
          <PrivateRoute exact path="/top" render={() => <Top />} userState={userState} />
        ) : (
          <Shell>
            <Route
              exact
              path="/"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? (
                  <Redirect to="/top" />
                ) : (
                  <Redirect to="/pwa" />
                );
              }}
            />
            <Route
              exact
              path="/signin"
              render={() => {
                localStorage.setItem('mode', '');
                return userState.authState === AuthState.SignedIn && userState.user ? (
                  <Redirect to="/top" />
                ) : localStorage.getItem('agreed') === 'true' ? (
                  <Signin />
                ) : (
                  <Redirect to="/pwa" />
                );
              }}
            />
            <Route
              exact
              path="/stories/:episodeId"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? <Stories /> : <Signin />;
              }}
            />
            <Route exact path="/signup" render={() => <Signup />} />
            <Route exact path="/signup_grp" render={() => <SignupGrp />} />
            <PrivateRoute exact path="/grammar/:episodeId" render={() => <Grammar />} userState={userState} />
            <PrivateRoute exact path="/story/:episodeId" render={() => <Story />} userState={userState} />
            <PrivateRoute exact path="/expression/:episodeId" render={() => <Expression />} userState={userState} />
            <PrivateRoute exact path="/exercises/:episodeId" render={() => <Exercises />} userState={userState} />
            <PrivateRoute exact path="/question/:episodeId" render={() => <Question />} userState={userState} />
            <PrivateRoute exact path="/answer/:episodeId" render={() => <Answer />} userState={userState} />
            <PrivateRoute exact path="/goal/:episodeId" render={() => <Goal />} userState={userState} />
            <PrivateRoute exact path="/self_study" render={() => <SelfStudy />} userState={userState} />
            <PrivateRoute exact path="/wordbank_noun" render={() => <WordbankNoun />} userState={userState} />
            <PrivateRoute exact path="/wordbank_verb" render={() => <WordbankVerb />} userState={userState} />
            <PrivateRoute exact path="/wordbank_adjective" render={() => <WordbankAdjective />} userState={userState} />
            <PrivateRoute
              exact
              path="/word_cards/:dataID/:idx/:index"
              render={() => <WordCards />}
              userState={userState}
            />
            <PrivateRoute exact path="/grammar_notes/:index" render={() => <GrammarNotes />} userState={userState} />
            <PrivateRoute exact path="/grammar_list" render={() => <GrammarList />} userState={userState} />
            <PrivateRoute exact path="/times" render={() => <Times />} userState={userState} />
            <PrivateRoute exact path="/dates" render={() => <Dates />} userState={userState} />
            <PrivateRoute exact path="/drills_answer" render={() => <DrillsAnswer />} userState={userState} />
            <PrivateRoute exact path="/drills_goal" render={() => <DrillsGoal />} userState={userState} />
            <PrivateRoute exact path="/drills_list" render={() => <DrillsList />} userState={userState} />
            <PrivateRoute exact path="/drills_question" render={() => <DrillsQuestion />} userState={userState} />
            <PrivateRoute exact path="/drills_top" render={() => <DrillsTop />} userState={userState} />
            <PrivateRoute exact path="/online_lesson" render={() => <OnlineLesson />} userState={userState} />
            <PrivateRoute exact path="/notices" render={() => <Notices />} userState={userState} />
            <PrivateRoute exact path="/settings" render={() => <Settings />} userState={userState} />
            <PrivateRoute exact path="/reserve_dates" render={() => <ReserveDates />} userState={userState} />
            <PrivateRoute exact path="/reserve_times" render={() => <ReserveTimes />} userState={userState} />
            <PrivateRoute exact path="/reserve_contents" render={() => <ReserveContents />} userState={userState} />
            <PrivateRoute exact path="/q_and_a" render={() => <QAndA />} userState={userState} />
            <PrivateRoute exact path="/point" render={() => <Point />} userState={userState} />
            <PrivateRoute exact path="/point_history" render={() => <PointHistory />} userState={userState} />
            <PrivateRoute exact path="/study_records" render={() => <StudyRecords />} userState={userState} />
            <PrivateRoute exact path="/subscription" render={() => <Subscription />} userState={userState} />
            <PrivateRoute exact path="/questionnaire" render={() => <Questionnaire />} userState={userState} />
            <PrivateRoute exact path="/push_confirm" render={() => <PushConfirm />} userState={userState} />
            <PrivateRoute exact path="/hiragana_a-n" render={() => <HiraganaAn />} userState={userState} />
            <PrivateRoute exact path="/hiragana_ga-po" render={() => <HiraganaGaPo />} userState={userState} />
            <PrivateRoute exact path="/hiragana_kya-pyo" render={() => <HiraganaKyaPyo />} userState={userState} />
            <PrivateRoute
              exact
              path="/hiragana_a-n_contents/:id"
              render={() => <HiraganaAnContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/hiragana_ga-po_contents/:id"
              render={() => <HiraganaGaPoContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/hiragana_kya-ryo_contents/:id"
              render={() => <HiraganaKyaRyoContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/hiragana_gya-pyo_contents/:id"
              render={() => <HiraganaGyaPyoContents />}
              userState={userState}
            />
            <PrivateRoute exact path="/hiragana_exercises" render={() => <HiraganaExercises />} userState={userState} />
            <PrivateRoute exact path="/hiragana_question" render={() => <HiraganaQuestion />} userState={userState} />
            <PrivateRoute exact path="/hiragana_answer" render={() => <HiraganaAnswer />} userState={userState} />
            <PrivateRoute exact path="/hiragana_goal" render={() => <HiraganaGoal />} userState={userState} />
            <PrivateRoute exact path="/katakana_a-n" render={() => <KatakanaAn />} userState={userState} />
            <PrivateRoute exact path="/katakana_ga-po" render={() => <KatakanaGaPo />} userState={userState} />
            <PrivateRoute exact path="/katakana_kya-pyo" render={() => <KatakanaKyaPyo />} userState={userState} />
            <PrivateRoute
              exact
              path="/katakana_a-n_contents/:id"
              render={() => <KatakanaAnContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/katakana_ga-po_contents/:id"
              render={() => <KatakanaGaPoContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/katakana_kya-ryo_contents/:id"
              render={() => <KatakanaKyaRyoContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/katakana_gya-pyo_contents/:id"
              render={() => <KatakanaGyaPyoContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/katakana_ie-dou_contents/:id"
              render={() => <KatakanaIeDouContents />}
              userState={userState}
            />
            <PrivateRoute
              exact
              path="/katakana_fuyu-deyu_contents/:id"
              render={() => <KatakanaFuyuDeyuContents />}
              userState={userState}
            />
            <PrivateRoute exact path="/katakana_exercises" render={() => <KatakanaExercises />} userState={userState} />
            <PrivateRoute exact path="/katakana_question" render={() => <KatakanaQuestion />} userState={userState} />
            <PrivateRoute exact path="/katakana_answer" render={() => <KatakanaAnswer />} userState={userState} />
            <PrivateRoute exact path="/katakana_goal" render={() => <KatakanaGoal />} userState={userState} />
            <Route
              exact
              path="/user_info"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? <UserInfo /> : <Signin />;
              }}
            />
            <Route
              exact
              path="/contact"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? <Contact /> : <Signin />;
              }}
            />
            <Route
              exact
              path="/lesson"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? <Lesson /> : <Signin />;
              }}
            />
            <Route
              exact
              path="/lessons"
              render={() => {
                return userState.authState === AuthState.SignedIn && userState.user ? <Lessons /> : <Signin />;
              }}
            />
          </Shell>
        )}
      </React.Suspense>
    </Switch>
  );
};

//インストールされたPWAのウインドーのサイズ
window.addEventListener('resize', function () {
  let fixedWidth = 375;
  let fixedHeight = 667;
  window.resizeTo(fixedWidth, fixedHeight);
});

export const reloadIfTokenExpired = (e: any) => {
  const rtn: AxiosResponse = {
    data: [] as never,
    status: 0,
    statusText: '',
    headers: '' as never,
    config: '' as never,
  };
  if (e.message.match(/401/) || e.message.match(/404/)) {
    localStorage.clear();
    window.location.reload();
    return rtn;
  }
  return rtn;
};

export const exchangeTimeZone = (datedime: Date) => {
  // console.log(datedime);
  // ローカル時間に変更
  let localDatetime = datedime.toLocaleString();
  // 出力用にフォーマット
  let fromattedTime = format(Date.parse(localDatetime), 'yyyy/MM/dd HH:mm:ss');
  // console.log(fromattedTime);
  return fromattedTime;
};
export const exchangeTimeZone2 = (datetime: Date) => {
  let today = datetime;
  if (typeof datetime == 'string') {
    today = new Date(today);
  }
  // 出力用にフォーマット(入力値をそのまま出力)
  let fromattedTime =
    today.getUTCFullYear() +
    '/' +
    (today.getUTCMonth() + 1).toString().padStart(2, '0') +
    '/' +
    today.getUTCDate().toString().padStart(2, '0') +
    ' ' +
    today.getUTCHours().toString().padStart(2, '0') +
    ':' +
    today.getUTCMinutes().toString().padStart(2, '0') +
    ':' +
    today.getUTCSeconds().toString().padStart(2, '0');
  return fromattedTime;
};

export const formatYMD = (YMD: string) => {
  return `${YMD.substring(0, 4)}/${YMD.substring(4, 6)}/${YMD.substring(6, 8)}`;
};

export default App;
