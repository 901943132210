import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface LoadingState {
  value: boolean;
}

const initialState: LoadingState = {
  value: true,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.value = true;
    },
    stopLoading: (state) => {
      state.value = false;
    },
  },
});

export const { startLoading, stopLoading } = loadingSlice.actions;

export const isLoading = (state: RootState) => state.loading.value;

export default loadingSlice.reducer;
