import { Translations } from '@aws-amplify/ui-components';
export const COGNITO_TRANSLATIONS = {
  [Translations.CONFIRM_TOTP_CODE]: '2要素認証を行います',
  [Translations.CODE_LABEL]: '認証コード',
  [Translations.CREATE_ACCOUNT_TEXT]: 'アカウントの作成',
  [Translations.EMAIL_LABEL]: 'メールアドレス *',
  [Translations.EMAIL_PLACEHOLDER]: ' ',
  [Translations.FORGOT_PASSWORD_TEXT]: 'パスワードをお忘れの場合',
  [Translations.PASSWORD_LABEL]: 'パスワード *',
  [Translations.PASSWORD_PLACEHOLDER]: ' ',
  [Translations.RESET_PASSWORD_TEXT]: 'パスワードを変更する',
  [Translations.SIGN_IN_ACTION]: 'ログイン',
  [Translations.SIGN_IN_HEADER_TEXT]: 'ログイン',
};
