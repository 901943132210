import {
  REACT_APP_TERMS_OF_SERVICE_URL,
  REACT_APP_TERMS_OF_SERVICE_URL_EN,
  REACT_APP_TERMS_OF_SERVICE_URL_CN,
} from '../configs';

/**
 * 利用規約リンク取得
 * 
 * systemLanguageを取得して適切な利用規約のリンクを返す
 */
const getTermsLink = () => {
  const systemLanguage = localStorage.getItem('systemLanguage');
  const url = {
    ja: REACT_APP_TERMS_OF_SERVICE_URL ?? "",
    en: REACT_APP_TERMS_OF_SERVICE_URL_EN ?? "",
    ch: REACT_APP_TERMS_OF_SERVICE_URL_CN ?? "",
  };
  let resultUrl = ""
  if (systemLanguage && (systemLanguage === 'ja' || systemLanguage === 'en' || systemLanguage === 'ch')) {
    resultUrl =  url[systemLanguage];
  } else {
    resultUrl =  url['en'];
  }
  return resultUrl
};

export const linkUtils = {
    getTermsLink
}