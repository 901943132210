import './index.scss';
import {
  Button,
  Flex,
  Image,
  Stack,
  Select,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const SelectLang = () => {
  //assets
  const kurashi_study_logo = require('../../assets/images/kurashi_study_logo.png');
  const system_words: any = {};
  system_words['ja'] = require('../../assets/jsons/lang/ja.json');
  system_words['en'] = require('../../assets/jsons/lang/en.json');
  system_words['vn'] = require('../../assets/jsons/lang/vn.json');
  system_words['ch'] = require('../../assets/jsons/lang/ch.json');

  //states
  var language = navigator.language.substring(0,2);
  const [selectedLang, setSelectedLang] = useState<string>(language==='ja'? 'ja'
  :language==='vi'?'vn':language ==='en'?'en':language==='zh'?'ch':'en');

  const setLang = (lng: string) => {
    setSelectedLang(lng);
  };

  const submitToAgree = () => {
    localStorage.setItem('systemLanguage', selectedLang);
    // 翻訳アイコンで表示する言語の設定(日本語選択時は英語)
    localStorage.setItem('translationLanguage', selectedLang == 'ja'? 'en': selectedLang == 'vn'? 'ch': selectedLang);
    window.location.replace('/agree');
  }

  return (
    <>
      <Stack id="bg-image" pb="5px" m="auto" w="375px" bgSize="cover" boxSizing="content-box" bgColor="white" justify="center" >
      <Flex justifyContent="center" minHeight="64px">
        <Image src={kurashi_study_logo} px="50px" mt="100px" mb="20px"/>
      </Flex>
      <Flex textAlign="center" justifyContent="center" px="50px" pb="20px">
        <Text fontSize="24px">{system_words[selectedLang]['KT_0001_01']}</Text>
      </Flex>
        <Flex id="lang_dw" justifyContent="center" margin="5px" pb="20px">
        <form>
          <Select id="select_lang" size="md" minWidth="300px" onChange={event => setLang(event.currentTarget.value)}>
            <option value="ja" selected={selectedLang == 'ja'? true:false}>日本語</option>
            <option value="en" selected={selectedLang == 'en'? true:false}>English</option>
            <option value="vn" selected={selectedLang == 'vn'? true:false}>Tiếng Việt</option>
            <option value="ch" selected={selectedLang == 'ch'? true:false}>中文</option>
          </Select>
        </form>
      </Flex>
      <Flex justifyContent="center" margin="5px" pb="20px">
        <Button
          id="single_btn"
          className="btn-border"
          onClick={() => submitToAgree()}
          textColor="white"
          bgColor="orange"
          w="300px"
        >
          {system_words[selectedLang]['KT_0001_02']}
        </Button>
      </Flex>
      <Flex textAlign="center" justifyContent="center" px="50px" pb="20px">
        <Text fontSize="18px">{system_words[selectedLang]['KT_0001_03']}</Text>
      </Flex>
      </Stack>
    </>
  );
};

export default SelectLang;
