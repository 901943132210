import { Box, Container, Flex, Link, Menu, MenuButton, Image } from '@chakra-ui/react';
import { memo, useEffect, useState } from 'react';
import { useAppSelector } from '../hooks';
import './shell.scss';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getDisplayLangData } from '../component/json';

const NavItem = ({ icon, title, active, onClick }: any) => {
  return (
    <Flex className="ButtomMenu">
      <Menu>
        <Link
          backgroundColor={active ? '#eeeeee' : 'white'}
          p={1}
          _hover={{ textDecor: 'none', backgroundColor: '#eeeeee' }}
          w={'auto'}
          onClick={onClick}
        >
          <MenuButton paddingX="0" textAlign="center">
            <Flex justifyContent="center">
              <Box textAlign="center">
                <Image src={icon} boxSize="45px" />
              </Box>
            </Flex>
            <Flex justifyContent="center" fontSize="1sm">
              {title}
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
};

const navs = (Lang: any) => {
  return [
    {
      icon: require('../assets/images/home/ホームアイコン_s.png'),
      title: Lang.KT_0047_06,
      active: false,
      pathname: '/top',
    },
    {
      icon: require('../assets/images/bottom_story_mode.png'),
      title: Lang.KT_0047_01,
      active: false,
      pathname: '/stories/1',
    },
    {
      icon: require('../assets/images/bottom_self_learning.png'),
      title: Lang.KT_0047_02,
      active: false,
      pathname: '/self_study',
    },
    {
      icon: require('../assets/images/bottom_japan_online.png'),
      title: Lang.KT_0047_03,
      active: false,
      pathname: '/online_lesson',
    },
  ];
};

export interface IShellProps {
  children: React.ReactNode;
}

export const Shell: React.NamedExoticComponent<IShellProps> = memo(({ children }) => {
  const [Lang, setLang] = useState<any>({});

  const historyState = useAppSelector((state) => state.router);
  const { pathname } = useLocation();


  useEffect(() => {
    setLang(getDisplayLangData());
  }, []);

  const mode = localStorage.getItem('mode');
  return (
    <>
      <Container userSelect="none" className="Shell-children" paddingInline="0em" width="100%" maxWidth={"100%"}>
        {children}
      </Container>
      <Flex
        userSelect="none"
        direction="row"
        position="fixed"
        bottom="0"
        width="100%"
        justifyContent="center"
        textAlign="center"
        fontSize="sm"
        bgColor="white"
        zIndex="100"
        hidden={
          pathname.includes('signin') ||
          mode === 'story' ||
          pathname.includes('questionnaire') ||
          pathname.includes('signup') ||
          pathname.includes('signup_grp') ||
          pathname.includes('hiragana_a-n') ||
          pathname.includes('hiragana_ga-po') ||
          pathname.includes('hiragana_kya-pyo') ||
          pathname.includes('hiragana_a-n_contents') ||
          pathname.includes('hiragana_ga-po_contents') ||
          pathname.includes('hiragana_kya-ryo_contents') ||
          pathname.includes('hiragana_gya-pyo_contents') ||
          pathname.includes('hiragana_exercises') ||
          pathname.includes('hiragana_question') ||
          pathname.includes('hiragana_answer') ||
          pathname.includes('hiragana_goal') ||
          pathname.includes('katakana_a-n') ||
          pathname.includes('katakana_ga-po') ||
          pathname.includes('katakana_kya-pyo') ||
          pathname.includes('katakana_a-n_contents') ||
          pathname.includes('katakana_ga-po_contents') ||
          pathname.includes('katakana_kya-ryo_contents') ||
          pathname.includes('katakana_gya-pyo_contents') ||
          pathname.includes('katakana_ie-dou_contents') ||
          pathname.includes('katakana_fuyu-deyu_contents') ||
          pathname.includes('katakana_exercises') ||
          pathname.includes('katakana_question') ||
          pathname.includes('katakana_answer') ||
          pathname.includes('katakana_goal') 
        }
      >
        {navs(Lang).map((nav, i) => {
          const menus = {
            top: 'top',
            story: 'stories',
            selfStudy: 'self_study',
            lesson: 'online_lesson',
          } as const;
          let navId = '';
          if (nav.pathname.includes(menus.top)) {
            navId = menus.top;
          } else if (nav.pathname.includes(menus.story)) {
            navId = menus.story;
          } else if (nav.pathname.includes(menus.selfStudy)) {
            navId = menus.selfStudy;
          } else if (nav.pathname.includes(menus.lesson)) {
            navId = menus.lesson;
          }

          return (
            <span id={navId}>
              <NavItem
                key={i}
                icon={nav.icon}
                title={nav.title}
                active={nav.pathname === historyState.location.pathname}
                onClick={() => window.location.replace(nav.pathname)}
              />
            </span>
          );
        })}
      </Flex>
    </>
  );
});
