import './index.scss';
import { Box, Button, Flex, Image, Stack, Link } from '@chakra-ui/react';
import { REACT_APP_SPECIFIED_COMMERCIAL_URL } from '../../configs';
import { getDisplayLangData } from '../../component/json';

const Start = () => {
  const Lang = getDisplayLangData();
  const kurashi_study_logo = require('../../assets/images/kurashi_study_logo.png');

  const onSignup = () => {
    window.location.replace(`/signup`);
  };
  const onSignupGrp = () => {
    window.location.replace(`/signup_grp`);
  };
  const onSignin = () => {
    window.location.replace(`/signin`);
  };

  return (
    <>
      <Stack
        id="bg-image"
        pb="5px"
        m="auto"
        w="375px"
        bgSize="cover"
        boxSizing="content-box"
        bgColor="white"
        justify="center"
      >
        <Flex justifyContent="center" minHeight="64px">
          <Image src={kurashi_study_logo} px="50px" mt="100px" mb="20px" />
        </Flex>
        <Flex textAlign="center" justifyContent="center" px="50px" fontSize="14px">
          {Lang.KT_0008_01}
        </Flex>
        <Flex justifyContent="center" margin="5px" pb="20px">
          <Box>
            <Button
              id="single_btn"
              className="btn-border"
              onClick={onSignup}
              textColor="white"
              bgColor="orange"
              w="300px"
            >
              {Lang.KT_0008_02}
            </Button>
          </Box>
        </Flex>
        <Flex textAlign="center" justifyContent="center" px="50px" fontSize="14px">
          {Lang.KT_0008_05}
        </Flex>
        <Flex justifyContent="center" margin="5px" pb="20px">
          <Box>
            <Button
              id="group_btn"
              className="btn-border"
              onClick={onSignupGrp}
              textColor="white"
              bgColor="orange"
              w="300px"
            >
              {Lang.KT_0008_06}
            </Button>
          </Box>
        </Flex>
        <Flex textAlign="center" justifyContent="center" px="50px" fontSize="13px">
          {Lang.KT_0008_03}
        </Flex>
        <Flex justifyContent="center" margin="5px" pb="20px">
          <Box>
            <Button
              id="signin_btn"
              className="btn-border"
              onClick={onSignin}
              textColor="white"
              bgColor="orange"
              w="300px"
            >
              {Lang.KT_0008_04}
            </Button>
          </Box>
        </Flex>
        <Flex justifyContent="center" pb="20px">
          {/* 特定商取引法 */}
          <Link
            href={REACT_APP_SPECIFIED_COMMERCIAL_URL}
            target="_blank"
            m="10px"
            color="orange"
            fontWeight="bold"
            fontSize="13px"
            textDecoration="underline"
          >
            {Lang.KT_0041_07}
          </Link>
        </Flex>
      </Stack>
    </>
  );
};

export default Start;
