import React, { useEffect } from 'react';
import './index.scss';
import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IS_DEV } from '../../configs';
import { getDisplayLangData } from '../../component/json';
import img_logo_w from '../../assets/images/img_logo_w.svg'
import logo_kokoka from '../../assets/images/logo_kokoka.svg'
import launchOutlined from '../../assets/images/LaunchOutlined.png'
interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

const PWAInstall = () => {
  const Lang = getDisplayLangData();

  //states
  var language = navigator.language.substring(0,2);
  const [browserLang, setBrowserLang] = useState<string>(language==='ja'? 'ja'
  :language==='vi'?'vn':language ==='en'?'en':language==='zh'?'ch':'en');
  const [pwaInstalled, setPwaInstalled] = useState(true);  //TODO: 勝手にインストールされているの判定
  const [swAvailable, setSwAvailable] = useState('serviceWorker' in navigator);
  const [swRegistered, setSwRegistered] = useState(false);
  const system_words: any = {};
  system_words['ja'] = require('../../assets/jsons/lang/ja.json');
  system_words['en'] = require('../../assets/jsons/lang/en.json');
  system_words['vn'] = require('../../assets/jsons/lang/vn.json');
  system_words['ch'] = require('../../assets/jsons/lang/ch.json');

  useEffect(() => {
    //PWA installの前のダイアログを表示するのために
    let deferredPrompt: IBeforeInstallPromptEvent | null;
    window.addEventListener('beforeinstallprompt', (e: any) => {
      deferredPrompt = e as IBeforeInstallPromptEvent;
      setPwaInstalled(false); 
      setSwRegistered(true);
    });

    //PWAサポート有りのブラウザーで表示する
    window.addEventListener('beforeinstallprompt', (e: any) => {
      deferredPrompt = e as IBeforeInstallPromptEvent;
    });

    //PWAインストールをする
    const installApp = document.getElementById('install_btn');
    installApp?.addEventListener('click', async () => {
        if (deferredPrompt !== null || deferredPrompt !== undefined ) {
            deferredPrompt?.prompt();
            if ((await deferredPrompt?.userChoice)?.outcome === 'accepted') {
                deferredPrompt = null;
                setPwaInstalled(true); 
                window.location.replace(`/select_lang`);
            } 
        } else {
          deferredPrompt = null;
      }
    });

    //SWが存在されていない
    if(swAvailable){
      navigator.serviceWorker.getRegistration('/').then(async registration => {
        setSwRegistered(registration?true:false);
      })
    }
  }, []);

  useEffect(() => {
      if(!swRegistered) newSW();
    }, [swRegistered]);   

  const newSW = async () => {
    await navigator.serviceWorker?.register(IS_DEV?"http://localhost:3003/sw.js":"/sw.js")
    .then(registration => {
      //SWを登録された
    })
    .catch(err => {
      //SWを登録されるエラー
    });
  }

  function onStart(event:any) {
    if (!window.matchMedia('(display-mode: standalone)').matches && swRegistered && pwaInstalled) { 
      //PWAアプリがインストールされているので、ダイアログを表示する
      onOpenModal('pwaModal');
    } else {
      window.location.replace(`/select_lang`);
    }
  }

  // Modal切り分け用
  const [openModal, setOpenModal] = useState<string>('');
  const onOpenModal = (name: string) => {
    setOpenModal(name);
  };
  const onCloseModal = () => {
    setOpenModal('');
  };

  return (
    <>
      <Modal isOpen={'pwaModal' === openModal} onClose={onCloseModal} >
        <ModalOverlay />
        <ModalContent userSelect="none" maxWidth="345px">
          <ModalHeader bgColor="grey" roundedTop={'md'} textColor="white" textAlign="center">
            {/* くらしスタディをはじめる */}
            {Lang.KT_0002_09}
          </ModalHeader>
          <ModalBody>
            {/* 右上のアイコン、またはデスクトップのアイコンから起動してください。 */}
          {Lang.KT_0002_10_1}
          <Image id="launchOutlined" src={launchOutlined} h="30px"/>
          {Lang.KT_0002_10_2}
          </ModalBody>
          <ModalFooter justifyContent="space-evenly">
              <Button className="btn-border" w="40%" onClick={onCloseModal}>OK</Button>
              <Button className="btn-border" w="50%" onClick={() => window.location.replace('/select_lang')}>
                {/* ブラウザで始める */}
                {Lang.KT_0002_08}
                </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    <Stack pb="5px" m="auto" w="375px" h="620px" bgSize="cover" boxSizing="content-box" bgColor="#F69825" justify="center" >
      <Flex justifyContent="center" minHeight="54px">
        <Image src={logo_kokoka} />
      </Flex>
      <Flex justifyContent="center" minHeight="198px">
        <Image src={img_logo_w} px="20px" my="60px"/>
      </Flex>
      <Flex justifyContent="center" pb="20px" minHeight="60px">
        <Button
          id="install_btn"
          className="btn-border"
          textColor="#F69825"
          bgColor="white"
          w="90%"
          disabled={!swAvailable || !swRegistered || pwaInstalled}
          hidden={pwaInstalled}
        >
          {system_words[browserLang]['KT_0002_07']}
        </Button>
      </Flex>
      <Flex justifyContent="center" pb="20px">
        <Button
          id="start_btn"
          className="btn-border"
          onClick={(event: any) => onStart(event)}
          textColor="#F69825"
          bgColor="white"
          w="90%"
          >
          {system_words[browserLang]['KT_0002_04']}
        </Button>
      </Flex>      
      <Flex justifyContent="center" margin="5px" pt="50px" pb="20px">
        <Box>Copyright ©SUN Co.,Ltd All Rights Reserved</Box>
      </Flex>
    </Stack>
    </>
  );
};

export default PWAInstall;